<script setup lang="ts">
import type { Ref } from "vue";
import { getCategoryBreadcrumbs } from "@shopware/helpers";
import { useCmsHead } from "@/composables/useCmsHead";
import type { Schemas } from "#shopware";
import {useCachedCategory} from "~/components/server/useCachedCategory";

const runtimeConfig = useRuntimeConfig();

const props = defineProps<{
  navigationId: string;
}>();

const route = useRoute();

const categoryResponse = ref();
categoryResponse.value = await useCachedCategory(props.navigationId, route.query);

if (!categoryResponse.value) {
  console.error("[FrontendNavigationPage.vue]", 'Category could not be found or loaded.');
  throw createError({
    statusCode: 404,
    statusMessage: `Category could not be found or loaded.`,
  });
}

const breadcrumbs = getCategoryBreadcrumbs(categoryResponse.value, {
  startIndex: 1,
});

useBreadcrumbs(breadcrumbs);

const { category } = useCategory(categoryResponse as Ref<Schemas["Category"]>);
useCmsHead(category, { mainShopTitle: runtimeConfig.public.general.shopTitle });

if(category.value.translated?.metaTitle) {
  useHead({
    title: category.value.translated.metaTitle + ' | ' + useRuntimeConfig().public.general.shopTitle,
  });
} else {
  useHead({
    title: runtimeConfig.public.general.shopTitle
  });
}

function appendScriptToElement(scriptSrc: string, elementId: string) :void {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', scriptSrc);
  scriptElement.setAttribute("defer", "defer");
  let element = document.getElementById(elementId);
    if(element) {
        element.appendChild(scriptElement);
    }
}

onMounted( ()=> {
  if (import.meta.client) {
    setTimeout(() => {
      appendScriptToElement('https://app.addsauce.com/widgets/widget_loader/f0ba9a87-9453-1fe5-cff8-9a7c99809711/ootd.js', 'welove-addsauce');
    }, 1000)
  }
})

</script>

<template>
  <!--LayoutBreadcrumbs v-if="route.path != '/' && route.path != '/fr-FR'" /-->
  <CmsPage v-if="category?.cmsPage" :content="category.cmsPage" />

  <template v-if="route.path.includes('/welovezebra/')">
    <section class="py-6">
      <div class="container mx-auto">
        <div id="welove-addsauce"></div>
      </div>
    </section>
  </template>
</template>
